<template>
    <div :class="[s.container, theme.profile.profile]">
        <div>
            <p :class="s.goBack" @click="$router.push('/profile')">
                ← Go back
            </p>
            <div :class="s.module">
                <div :class="s.header">
                    <span :class="[theme.profile.text, theme.profile.large, theme.profile.extrabold, theme.profile.black]">
                        Changing password
                    </span>
                </div>
                <div :class="s.content">
                    <form @submit.prevent="changePassword">
                        <TextInput
                            title="Old Password"
                            @input-text="changePasswordForm.oldPassword = $event; errors.oldPassword = false"
                            :value="changePasswordForm.oldPassword"
                            is-password
                            :has-error="errors.oldPassword"
                            error-text="Password min length is 8 letters.<br>Password max length is 100 letters"
                        />
                        <TextInput
                            title="New password"
                            @input-text="changePasswordForm.newPassword = $event; errors.newPassword = false"
                            is-password
                            :value="changePasswordForm.newPassword"
                            :has-error="errors.newPassword"
                            error-text="Password min length is 8 letters.<br>Password max length is 100 letters"
                        />
                        <TextInput
                            title="Confirm New Password"
                            @input-text="changePasswordForm.confirmPassword = $event; errors.confirmPassword = false"
                            is-password
                            :value="changePasswordForm.confirmPassword"
                            :has-error="errors.confirmPassword"
                            error-text="Passwords must match"
                        />
                        <Button
                            is-submit
                            button-type="primary"
                            wide
                        >
                            <template>
                                Save
                            </template>
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { maxLength, minLength, required, sameAs } from 'vuelidate/lib/validators';

import Button from 'Control/Button.vue';
import theme from 'Theme';
import TextInput from 'Control/TextInput.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { passwordRegExpCheck } from 'Lib/utils/regExpRegistrationValidator';

export default {
    name: 'ProfileChangePassword',
    components: {
        Button,
        TextInput,
    },
    data() {
        return {
            changePasswordForm: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            errors: {
                oldPassword: false,
                newPassword: false,
                confirmPassword: false,
            },
            theme,
        };
    },
    validations() {
        return {
            changePasswordForm: {
                oldPassword: {
                    required,
                },
                newPassword: {
                    required,
                    minLength: minLength(8),
                    maxLength: maxLength(72),
                    passwordRegExpCheck,
                },
                confirmPassword: {
                    sameAsPassword: sameAs('newPassword'),
                },
            },
        };
    },
    methods: {
        async changePassword() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch('Auth/changePassword', this.changePasswordForm);
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'Your password is successfully changed');
                    await this.$router.push('/profile');
                } catch (error) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong');
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            } else {
                if (this.$v.changePasswordForm.oldPassword.$invalid) {
                    this.errors.oldPassword = true;
                }
                if (this.$v.changePasswordForm.newPassword.$invalid) {
                    this.errors.newPassword = true;
                }
                if (this.$v.changePasswordForm.confirmPassword.$invalid) {
                    this.errors.confirmPassword = true;
                }
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    margin-top: calc(2 * var(--m-xxxl));
    justify-content: center;
    width: 100%;
    & .module {
        background: var(--cl-white);
        border-radius: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: 350px;
        & .header {
            padding: var(--m-m);
            border-bottom: 1px solid var(--cl-gray-light);
        }
        & .content {
            padding: var(--m-xl) var(--m-m) var(--m-m) var(--m-m);
            & form {
                display: flex;
                flex-direction: column;
                row-gap: var(--m-xl);
            }
        }
    }
    & .goBack {
        font-weight: var(--fw-bold);
        font-size: var(--fs-l);
        line-height: var(--fs-l);
        color: var(--cl-violet);
        cursor: pointer;
        margin-bottom: var(--m-s);
    }
}
</style>
